import "@Styles/Shared/UserProfile/resetPassword_sm.less";
import "@Styles/Shared/UserProfile/resetPassword_lg.less";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import passwordInput from './Inputs/passwordInput';
import confirmPasswordInput from './Inputs/confirmPasswordInput';
import { resetPasswordSubmitCall } from './_api';
export default {
  name: 'resetPassword',
  components: {
    passwordInput: passwordInput,
    confirmPasswordInput: confirmPasswordInput
  },
  data: function data() {
    return {
      config: {},
      validTokens: true,
      securityToken: '',
      email: '',
      updatePassword: '',
      confirmPassword: '',
      passwordsMatch: null,
      isFieldValid: {},
      validatedFields: [],
      isSubmitEnabled: true,
      success: false,
      errorMessage: '',
      errors: []
    };
  },
  methods: {
    init: function init(config) {
      var _this = this;

      if (config) {
        this.config = _objectSpread({}, config.resetPasswordSettings);
        this.validatedFields = config.validatedFields; // Display invalid token section if either of the required query params don't exist

        if (config.securityToken && config.securityToken !== '' && config.email && config.email !== '') {
          this.securityToken = config.securityToken;
          this.email = config.email;
        } else {
          this.validTokens = false;
          this.$nextTick(function () {
            _this.$refs.invalidButton && _this.$refs.invalidButton.focus();
          });
        }
      }
    },
    setIsFieldValid: function setIsFieldValid(fieldId, status) {
      this.$set(this.isFieldValid, fieldId, status);
    },
    submit: function submit() {
      var _this2 = this;

      this.isSubmitEnabled = false;
      this.$_clearErrorMessage();

      if (!this.$_validate()) {
        this.isSubmitEnabled = true;
        return;
      }

      resetPasswordSubmitCall(this.securityToken, this.updatePassword, this.email).then(function (data) {
        if (data.isValid) {
          // Display success section
          _this2.success = true;

          _this2.$nextTick(function () {
            _this2.$refs.successButton && _this2.$refs.successButton.focus();
          });
        } else {
          var errorMessage = data.errors && data.errors[0].message ? _this2.config.errorListHeadingText : _this2.config.generalErrorMessage;

          _this2.$_setErrorMessage(errorMessage, data.errors);
        }

        _this2.isSubmitEnabled = true;
      }).catch(function () {
        _this2.$_setErrorMessage(_this2.config.generalErrorMessage);

        _this2.isSubmitEnabled = true;
      });
    },
    $_validate: function $_validate() {
      var returnValue = true;
      var errorList = [];
      var requiredFormat = this.config.fieldRequiredMessageFormat || '';
      var invalidFormat = this.config.fieldInvalidMessageFormat || ''; // Recheck if passwords match since confirm password doesn't re-validate on input of password

      var confirmPasswordField = this.validatedFields.find(function (f) {
        return f.name === 'confirmPassword';
      });

      if (this.confirmPassword !== this.updatePassword) {
        this.$set(this.isFieldValid, confirmPasswordField.id, false);
        this.passwordsMatch = false;
      } else {
        this.$set(this.isFieldValid, confirmPasswordField.id, true);
        this.passwordsMatch = true;
      }

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.validatedFields[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var field = _step.value;

          if (!this[field.name] || this[field.name].length === 0) {
            // field is empty
            returnValue = false;
            errorList.push({
              message: requiredFormat.replace('{0}', field.label)
            });
            this.$set(this.isFieldValid, field.id, false);
          } else if (this.isFieldValid[field.id] === false) {
            // validation on the field has failed
            returnValue = false;
            errorList.push({
              message: invalidFormat.replace('{0}', field.label)
            });
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      if (!returnValue) {
        this.$_setErrorMessage(this.config.errorListHeadingText, errorList);
      }

      return returnValue;
    },
    $_setErrorMessage: function $_setErrorMessage(errorMessage, errorList) {
      var _this3 = this;

      this.errorMessage = errorMessage;
      errorList && errorList.forEach(function (e) {
        e.message && _this3.errors.push(e.message);
        e.fieldId && _this3.$set(_this3.isFieldValid, e.fieldId, false);
      });
    },
    $_clearErrorMessage: function $_clearErrorMessage() {
      this.errorMessage = '';
      this.errors = [];
    }
  }
};