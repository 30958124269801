var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.sdk
    ? _c("div", { staticClass: "socialConnect" }, [
        _c("div", { staticClass: "socialConnectContainer" }, [
          _c(
            "button",
            {
              staticClass: "socialConnectButton",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  _vm.connect(_vm.providerType)
                }
              }
            },
            [
              _c("img", {
                staticClass: "socialProviderImage",
                attrs: { src: _vm.providerImage }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "socialConnectButtonText" }, [
                _vm._v(_vm._s(_vm.providerName))
              ])
            ]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }