import "@Styles/Shared/UserProfile/Inputs/validatedInputBase_sm.less";
export default {
  props: {
    isValid: null,
    // only set to true or false when we want to show styles
    requiredMessage: {
      type: String
    }
  },
  computed: {
    validationClass: function validationClass() {
      if (this.isValid === true) {
        return 'validInput';
      } else if (this.isValid === false) {
        return 'invalidInput';
      }

      return null;
    }
  },
  methods: {
    setIsValid: function setIsValid(newVal) {
      this.$emit('set-is-valid', newVal);
    }
  }
};