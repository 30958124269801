import { REGISTER_API_URL, AUTHENTICATE_API_URL, REQUEST_PASSWORD_RESET_API_URL, RESET_PASSWORD_API_URL, EMAIL_API_URL, FEDERATED_LOGIN_API_URL, FEDERATED_LINK_API_URL, FEDERATED_SETTINGS_API_URL, SAVE_ANSWERS_API_URL } from './_constants';
/**
 * Send registration request.
 * @param {string} email The email for the request
 * @param {string} password The password for the request
 * @param {string} birthdate The birthdate for the request
 * @param {string} zipCode The zip code for the request
 * @param {Array} householdComp The household composition answers for the request
 * @param {string} languagePreference The culture code for the user's preferred language for the request
 * @param {string} newsletterSubListId The ID of the newsletter list to subscribe to. If no new subs, null or empty.
 * @param {any} federatedCredentials The Federated Credentials object
 * @param {string} recaptchaToken The Recaptcha token
 * @returns {Promise} The Promise from the fetch call.
 */

export var register = function register(email, password, birthdate, zipCode, householdComp, languagePreference, newsletterSubListId, federatedCredentials, recaptchaToken) {
  var requestBody = {
    email: email,
    password: password,
    birthdate: birthdate,
    zipCode: zipCode,
    householdComposition: householdComp,
    newsletterSubscriptionListId: newsletterSubListId,
    federatedCredentials: federatedCredentials,
    captchaResponse: recaptchaToken
  };

  if (languagePreference && languagePreference.length > 0) {
    requestBody.languagePreference = languagePreference;
  }

  return fetch(REGISTER_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestBody)
  }).then(function (response) {
    if (!response.ok) throw Error(response.statusText);
    return response.json();
  });
};
/**
 * Send authentication request
 * @param {string} email The email for the request
 * @param {string} password The password for the request
 * @param {boolean} rememberMe If the user desires to be remembered on this device for the request
 * @param {string} recaptchaToken The Recaptcha token
 * @returns {Promise} The Promise from the fetch call
 */

export var authenticate = function authenticate(email, password, rememberMe, recaptchaToken) {
  var requestBody = {
    email: email,
    password: password,
    rememberMe: rememberMe,
    captchaResponse: recaptchaToken
  };
  return fetch(AUTHENTICATE_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestBody)
  }).then(function (response) {
    if (!response.ok) throw Error(response.statusText);
    return response.json();
  });
};
/**
 * Calls the email validation API.
 * @param {string} email The email address to validate.
 * @returns {Promise} A promise to handle success or failure from caller.
 */

export var validateEmail = function validateEmail(email) {
  var searchParams = new URLSearchParams({
    'email': email
  });
  return fetch(EMAIL_API_URL + '?' + searchParams.toString()).then(function (response) {
    if (!response.ok) throw Error(response.statusText);
    return response.json();
  });
};
/**
 * Send password reset request
 * @param {string} email The email for the request
 * @returns {Promise} The Promise from the fetch call
 */

export var requestPasswordResetCall = function requestPasswordResetCall(email) {
  var requestBody = {
    email: email
  };
  return fetch(REQUEST_PASSWORD_RESET_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestBody)
  }).then(function (response) {
    if (!response.ok) throw Error(response.statusText);
    return response.json();
  });
};
/**
 * Send the new submitted password request
 * @param {string} securityToken The session token from the reset password email
 * @param {string} updatedPassword The new password the user has requested
 * @param {string} email The email of the user
 * @returns {Promise} The Promise from the fetch call
 */

export var resetPasswordSubmitCall = function resetPasswordSubmitCall(securityToken, updatedPassword, email) {
  var requestBody = {
    securityToken: securityToken,
    updatedPassword: updatedPassword,
    email: email
  };
  return fetch(RESET_PASSWORD_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestBody)
  }).then(function (response) {
    if (!response.ok) throw Error(response.statusText);
    return response.json();
  });
};
/**
 * Sends federated authorize request.
 * @param {string} loginProvider The login provider
 * @param {string} accessToken The access token
 * @param {string} idToken the ID token
 * @param {string} email The email address
 * @returns {Promise} The Promise from the fetch call
 */

export var federatedAuthorize = function federatedAuthorize(loginProvider, accessToken, idToken, email) {
  if (loginProvider === undefined || loginProvider === null) {
    return Promise.reject(new Error());
  }

  var requestParams = {
    loginProvider: loginProvider,
    accessToken: accessToken,
    idToken: idToken,
    email: email
  };
  return fetch(FEDERATED_LOGIN_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    cache: 'no-cache',
    body: JSON.stringify(requestParams)
  }).then(function (response) {
    if (!response.ok) throw Error(response.statusText);
    return response.json();
  });
};
/**
 * Sends federated link request.
 * @param {string} loginProvider The login provider
 * @param {string} accessToken The access token
 * @param {string} idToken the ID token
 * @param {any} linkCredentials The Link credentials object
 * @returns {Promise} The Promise from the fetch call
 */

export var federatedLink = function federatedLink(loginProvider, accessToken, idToken, linkCredentials) {
  if (loginProvider === undefined || loginProvider === null) {
    return Promise.reject(new Error());
  }

  var requestParams = {
    loginProvider: loginProvider,
    accessToken: accessToken,
    idToken: idToken,
    linkCredentials: linkCredentials
  };
  return fetch(FEDERATED_LINK_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    cache: 'no-cache',
    body: JSON.stringify(requestParams)
  }).then(function (response) {
    if (!response.ok) throw Error(response.statusText);
    return response.json();
  });
};
export var getFederatedAccountLinkSettings = function getFederatedAccountLinkSettings() {
  return fetch(FEDERATED_SETTINGS_API_URL, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    cache: 'no-cache'
  }).then(function (response) {
    if (!response.ok) throw Error(response.statusText);
    return response.json();
  });
};
/**
 * Save Answers.
 * @param {any} questions The questions for the request
 */

export var saveAnswers = function saveAnswers(questions) {
  var requestBody = {
    questions: questions
  };
  return fetch(SAVE_ANSWERS_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestBody)
  }).then(function (response) {
    if (!response.ok) throw Error(response.statusText);
    return response.json();
  });
};