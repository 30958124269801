var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "multiChoiceQuestionInput" }, [
    _c(
      "fieldset",
      { attrs: { "aria-invalid": _vm.isValid === false } },
      [
        _c("legend", [_vm._t("default")], 2),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "multiChoiceOptions fieldInputParent",
            class: _vm.displayValidation ? _vm.validationClass : ""
          },
          [
            _vm._l(_vm.choices, function(choice) {
              return _c("div", { key: choice.key, staticClass: "choiceBox" }, [
                _c("label", { staticClass: "choiceLabel" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.checkedValues,
                        expression: "checkedValues"
                      }
                    ],
                    staticClass: "choiceInput",
                    attrs: { type: "checkbox" },
                    domProps: {
                      value: choice.key,
                      checked: Array.isArray(_vm.checkedValues)
                        ? _vm._i(_vm.checkedValues, choice.key) > -1
                        : _vm.checkedValues
                    },
                    on: {
                      input: _vm.onInput,
                      change: function($event) {
                        var $$a = _vm.checkedValues,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = choice.key,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.checkedValues = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.checkedValues = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.checkedValues = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("span", {
                    staticClass: "choiceText",
                    domProps: { innerHTML: _vm._s(choice.value) }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "choiceSelectedDecoration" },
                    _vm._l(8, function(index) {
                      return _c("span", {
                        key: index,
                        class: "choiceSelected choiceSelected" + index
                      })
                    }),
                    0
                  )
                ])
              ])
            }),
            _vm._v(" "),
            _c("span", { staticClass: "validationSymbol" })
          ],
          2
        ),
        _vm._v(" "),
        _c("transition", { attrs: { name: "slide" } }, [
          _vm.displayValidation && _vm.isValid === false
            ? _c(
                "div",
                { staticClass: "inputErrors", attrs: { role: "alert" } },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.requiredMessage) +
                      "\n            "
                  )
                ]
              )
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }