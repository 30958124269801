import "@Styles/Shared/UserProfile/Inputs/passwordInput_sm.less";
import "@Styles/Shared/UserProfile/Inputs/passwordInput_lg.less";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import passwordInputBase from './passwordInputBase';
import { PASSWORD_FULL_REGEX, PASSWORD_ALPHA_REGEX, PASSWORD_NUMERIC_REGEX, PASSWORD_LENGTH_REGEX } from "../_constants";
import { testRegex } from '../_util';
export default {
  extends: passwordInputBase,
  props: {
    showCriteria: {
      type: Boolean
    },
    autocompleteValue: {
      type: String
    }
  },
  data: function data() {
    return {
      passwordCriteria: [],
      initialInput: true
    };
  },
  created: function created() {
    var _this = this;

    if (this.showCriteria) {
      this.$nextTick(function () {
        _this.passwordCriteria = _this.$_initializePasswordCriteria();
      });
    }
  },
  methods: {
    onInput: function onInput(event) {
      var _this2 = this;

      this.$emit('input', event.target.value);

      if (this.showCriteria) {
        this.$nextTick(function () {
          _this2.passwordCriteria.forEach(function (criteria, i) {
            return criteria.valid = testRegex(criteria.regex, _this2.value);
          });

          testRegex(PASSWORD_FULL_REGEX, _this2.value) ? _this2.setIsValid(true) : _this2.setIsValid(null);
        });
      }
    },
    onBlur: function onBlur() {
      var _this3 = this;

      this.initialInput = false;

      if (this.showCriteria) {
        this.$nextTick(function () {
          testRegex(PASSWORD_FULL_REGEX, _this3.value) ? _this3.setIsValid(true) : _this3.setIsValid(false);
        });
      }
    },
    $_initializePasswordCriteria: function $_initializePasswordCriteria() {
      return [{
        ref: 'letterCriteria',
        text: this.config.passwordLetterCriteriaText,
        regex: PASSWORD_ALPHA_REGEX,
        valid: null
      }, {
        ref: 'numberCriteria',
        text: this.config.passwordNumberCriteriaText,
        regex: PASSWORD_NUMERIC_REGEX,
        valid: null
      }, {
        ref: 'lengthCriteria',
        text: this.config.passwordLengthCriteriaText,
        regex: PASSWORD_LENGTH_REGEX,
        valid: null
      }];
    },
    $_criteriaClass: function $_criteriaClass(criteria) {
      if (criteria.valid === null) return '';

      if (this.initialInput) {
        return criteria.valid ? 'validInput' : '';
      } else {
        return criteria.valid ? 'validInput' : 'invalidInput';
      }
    }
  }
};