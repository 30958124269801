var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.config
    ? _c("div", { staticClass: "surveyRegistration" }, [
        _c(
          "div",
          { attrs: { "aria-labelledby": "registrationHeader" } },
          [
            _c(
              "transition-group",
              { attrs: { name: "slide" } },
              [
                !_vm.isRegistrationBlocked && !_vm.showSocialAccountLink
                  ? _c(
                      "div",
                      { key: "registrationForm" },
                      [
                        _c("div", { staticClass: "registrationHeading" }, [
                          _c(
                            "h2",
                            {
                              staticClass: "registrationHeaderText",
                              attrs: { id: "registrationHeader" }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.config.formTitle) +
                                  "\n                    "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "logInContainer" }, [
                            _c("span", { staticClass: "containerText" }, [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(_vm.config.alreadyAMemberText) +
                                  "\n                        "
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "containerLink",
                                attrs: { href: _vm.config.logInLinkUrl }
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.config.logInLinkText) +
                                    "\n                        "
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _vm.federatedLoginSettings != null &&
                          _vm.federatedLoginSettings.providers != null
                            ? _c("div", { staticClass: "socialLogin" }, [
                                _c(
                                  "ul",
                                  { staticClass: "socialConnectItems" },
                                  _vm._l(
                                    _vm.federatedLoginSettings.providers,
                                    function(provider) {
                                      return _c(
                                        "li",
                                        { staticClass: "socialConnectItem" },
                                        [
                                          _c(
                                            "social-connect",
                                            {
                                              attrs: {
                                                "provider-name":
                                                  provider.providerName,
                                                "provider-image":
                                                  provider.providerImage
                                              },
                                              on: {
                                                "social-sign-up-credentials":
                                                  _vm.setFederatedCredentials,
                                                "social-login-error":
                                                  _vm.socialLoginError,
                                                "social-login": _vm.socialLogin
                                              }
                                            },
                                            [
                                              _c("div", {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: false,
                                                    expression: "false"
                                                  }
                                                ],
                                                staticClass:
                                                  "socialConnectItemSkeleton"
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  0
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("transition", { attrs: { name: "slide" } }, [
                          _vm.socialMessage
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "errorSection socialErrorSection",
                                  attrs: { role: "alert" }
                                },
                                [
                                  _c("div", {
                                    staticClass: "errorHeading",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.socialMessage)
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c(
                          "form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                                return _vm.submit($event)
                              }
                            }
                          },
                          [
                            _c(
                              "fieldset",
                              {
                                ref: "registrationForm",
                                staticClass: "registrationForm"
                              },
                              [
                                _c("div", { staticClass: "requiredText" }, [
                                  _vm._v(
                                    "* " + _vm._s(_vm.config.requiredFieldText)
                                  )
                                ]),
                                _vm._v(" "),
                                _c("transition", { attrs: { name: "slide" } }, [
                                  !_vm.isSocialSignUp
                                    ? _c("div", [
                                        _c(
                                          "label",
                                          {
                                            staticClass:
                                              "registrationEmailSection"
                                          },
                                          [
                                            _c(
                                              "email-input",
                                              {
                                                attrs: {
                                                  "is-valid":
                                                    _vm.isFieldValid[
                                                      _vm.getValidationField(
                                                        "email"
                                                      ).id
                                                    ],
                                                  "required-message": _vm.getRequiredErrorMessage(
                                                    "email"
                                                  ),
                                                  config: _vm.config,
                                                  "validate-on-load":
                                                    _vm.validateEmailOnLoad
                                                },
                                                on: {
                                                  "set-is-valid": function(
                                                    $event
                                                  ) {
                                                    _vm.setIsFieldValid(
                                                      _vm.getValidationField(
                                                        "email"
                                                      ).id,
                                                      $event
                                                    )
                                                  },
                                                  "set-subscription-status":
                                                    _vm.setSubscriptionStatus
                                                },
                                                model: {
                                                  value: _vm.email,
                                                  callback: function($$v) {
                                                    _vm.email = $$v
                                                  },
                                                  expression: "email"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "registrationLabel"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.config.emailLabel
                                                      ) + " "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          "aria-hidden": "true"
                                                        }
                                                      },
                                                      [_vm._v("*")]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                false
                                                  ? _c("input", {
                                                      staticClass:
                                                        "inputSkeleton emailSkeleton",
                                                      attrs: {
                                                        type: "text",
                                                        readonly: ""
                                                      }
                                                    })
                                                  : _vm._e()
                                              ]
                                            )
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "registrationPasswordSection"
                                          },
                                          [
                                            _c(
                                              "password-input",
                                              {
                                                attrs: {
                                                  "is-valid":
                                                    _vm.isFieldValid[
                                                      _vm.getValidationField(
                                                        "password"
                                                      ).id
                                                    ],
                                                  "required-message": _vm.getRequiredErrorMessage(
                                                    "password"
                                                  ),
                                                  config: _vm.config,
                                                  "show-button-label":
                                                    _vm.config
                                                      .showPasswordButtonLabel,
                                                  "hide-button-label":
                                                    _vm.config
                                                      .hidePasswordButtonLabel,
                                                  "show-criteria": true,
                                                  "autocomplete-value":
                                                    "new-password"
                                                },
                                                on: {
                                                  "set-is-valid": function(
                                                    $event
                                                  ) {
                                                    _vm.setIsFieldValid(
                                                      _vm.getValidationField(
                                                        "password"
                                                      ).id,
                                                      $event
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: _vm.password,
                                                  callback: function($$v) {
                                                    _vm.password = $$v
                                                  },
                                                  expression: "password"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "registrationLabel"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.config.passwordLabel
                                                      ) + " "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        attrs: {
                                                          "aria-hidden": "true"
                                                        }
                                                      },
                                                      [_vm._v("*")]
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                false
                                                  ? _c("input", {
                                                      staticClass:
                                                        "inputSkeleton passwordSkeleton",
                                                      attrs: {
                                                        type: "text",
                                                        readonly: ""
                                                      }
                                                    })
                                                  : _vm._e()
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ])
                                    : _c(
                                        "div",
                                        {
                                          ref: "socialDetailSection",
                                          staticClass: "registrationSocial",
                                          attrs: { tabindex: "-1" }
                                        },
                                        [
                                          _c(
                                            _vm.socialProviderImage
                                              ? "img"
                                              : "",
                                            {
                                              tag: "component",
                                              staticClass: "providerImage",
                                              attrs: {
                                                src: _vm.socialProviderImage,
                                                alt: _vm.socialProviderName
                                              }
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "continueSocialText",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.socialDetailText
                                              )
                                            }
                                          })
                                        ],
                                        1
                                      )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "registrationBirthdateSection"
                                  },
                                  [
                                    _c(
                                      "birthdate-input",
                                      {
                                        attrs: {
                                          "is-valid":
                                            _vm.isFieldValid[
                                              _vm.getValidationField(
                                                "birthdate"
                                              ).id
                                            ],
                                          "required-message": _vm.getRequiredErrorMessage(
                                            "birthdate"
                                          ),
                                          config: _vm.config
                                        },
                                        on: {
                                          "set-is-valid": function($event) {
                                            _vm.setIsFieldValid(
                                              _vm.getValidationField(
                                                "birthdate"
                                              ).id,
                                              $event
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.birthdate,
                                          callback: function($$v) {
                                            _vm.birthdate = $$v
                                          },
                                          expression: "birthdate"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "registrationLabel" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.config.birthdayLabel) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                attrs: { "aria-hidden": "true" }
                                              },
                                              [_vm._v("*")]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        false
                                          ? _c("input", {
                                              staticClass:
                                                "inputSkeleton smallInput",
                                              attrs: {
                                                type: "text",
                                                readonly: ""
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "registrationZipCodeSection" },
                                  [
                                    _c(
                                      "zip-code-input",
                                      {
                                        attrs: {
                                          "is-valid":
                                            _vm.isFieldValid[
                                              _vm.getValidationField("zipCode")
                                                .id
                                            ],
                                          "required-message": _vm.getRequiredErrorMessage(
                                            "zipCode"
                                          ),
                                          config: _vm.config
                                        },
                                        on: {
                                          "set-is-valid": function($event) {
                                            _vm.setIsFieldValid(
                                              _vm.getValidationField("zipCode")
                                                .id,
                                              $event
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.zipCode,
                                          callback: function($$v) {
                                            _vm.zipCode = $$v
                                          },
                                          expression: "zipCode"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "registrationLabel" },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.config.zipCodeLabel) +
                                                " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                attrs: { "aria-hidden": "true" }
                                              },
                                              [_vm._v("*")]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        false
                                          ? _c("input", {
                                              staticClass:
                                                "inputSkeleton zipCodeSkeleton",
                                              attrs: {
                                                type: "text",
                                                readonly: ""
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "householdCompositionSection"
                                  },
                                  [
                                    _c(
                                      "mc-question-input",
                                      {
                                        attrs: {
                                          "is-valid":
                                            _vm.isFieldValid[
                                              _vm.getValidationField("hhComp")
                                                .id
                                            ],
                                          "required-message": _vm.getRequiredErrorMessage(
                                            "hhComp"
                                          ),
                                          choices:
                                            _vm.config
                                              .householdCompositionChoices,
                                          minimum:
                                            _vm.config
                                              .householdCompositionMinimum,
                                          "display-validation": true
                                        },
                                        on: {
                                          "set-is-valid": function($event) {
                                            _vm.setIsFieldValid(
                                              _vm.getValidationField("hhComp")
                                                .id,
                                              $event
                                            )
                                          }
                                        },
                                        model: {
                                          value: _vm.hhComp,
                                          callback: function($$v) {
                                            _vm.hhComp = $$v
                                          },
                                          expression: "hhComp"
                                        }
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "registrationLabel" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.config
                                                  .householdCompositionLabel
                                              ) + " "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                attrs: { "aria-hidden": "true" }
                                              },
                                              [_vm._v("*")]
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "registrationHelpText"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.config
                                                  .householdCompositionDescription
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        false
                                          ? _c("span", {
                                              staticClass: "hhCompSkeleton"
                                            })
                                          : _vm._e()
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.config.enableLanguagePreference
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "languagePreferenceSection"
                                      },
                                      [
                                        _c(
                                          "language-preference-input",
                                          {
                                            attrs: {
                                              "is-valid":
                                                _vm.isFieldValid[
                                                  _vm.getValidationField(
                                                    "languagePreference"
                                                  ).id
                                                ],
                                              "required-message": _vm.getRequiredErrorMessage(
                                                "languagePreference"
                                              ),
                                              config: _vm.config
                                            },
                                            on: {
                                              "set-is-valid": function($event) {
                                                _vm.setIsFieldValid(
                                                  _vm.getValidationField(
                                                    "languagePreference"
                                                  ).id,
                                                  $event
                                                )
                                              },
                                              "set-language-preference":
                                                _vm.setLanguagePreference
                                            },
                                            model: {
                                              value: _vm.languagePreference,
                                              callback: function($$v) {
                                                _vm.languagePreference = $$v
                                              },
                                              expression: "languagePreference"
                                            }
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "registrationLabel"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.config
                                                      .languagePreferenceLabel
                                                  ) + " "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    attrs: {
                                                      "aria-hidden": "true"
                                                    }
                                                  },
                                                  [_vm._v("*")]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            false
                                              ? _c("input", {
                                                  staticClass:
                                                    "inputSkeleton languagePreferenceSkeleton",
                                                  attrs: {
                                                    type: "text",
                                                    readonly: ""
                                                  }
                                                })
                                              : _vm._e()
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("transition", { attrs: { name: "slide" } }, [
                                  _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.config.newsletterListId &&
                                            !_vm.isSubscribed,
                                          expression:
                                            "config.newsletterListId && !isSubscribed"
                                        }
                                      ],
                                      staticClass:
                                        "registrationNewsletterSection"
                                    },
                                    [
                                      _c("fieldset", [
                                        _c(
                                          "legend",
                                          { staticClass: "newsletterHeading" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.config
                                                  .newsletterSubscriptionHeading
                                              )
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "label",
                                          { staticClass: "newsletterLabel" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "newsletterCheckboxSpacing"
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.newsletterSignUp,
                                                      expression:
                                                        "newsletterSignUp"
                                                    }
                                                  ],
                                                  attrs: {
                                                    type: "checkbox",
                                                    "aria-describedby":
                                                      "newsletterPreview"
                                                  },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.newsletterSignUp
                                                    )
                                                      ? _vm._i(
                                                          _vm.newsletterSignUp,
                                                          null
                                                        ) > -1
                                                      : _vm.newsletterSignUp
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      var $$a =
                                                          _vm.newsletterSignUp,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            (_vm.newsletterSignUp = $$a.concat(
                                                              [$$v]
                                                            ))
                                                        } else {
                                                          $$i > -1 &&
                                                            (_vm.newsletterSignUp = $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              ))
                                                        }
                                                      } else {
                                                        _vm.newsletterSignUp = $$c
                                                      }
                                                    }
                                                  }
                                                })
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "newsletterLabelText"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.config
                                                      .newsletterCheckboxLabel
                                                  )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ])
                                    ]
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "registrationSubmitSection" },
                                  [
                                    _c("div", {
                                      staticClass: "registrationLegalText",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.config.legalDisclaimerCopy
                                        )
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        attrs: {
                                          type: "submit",
                                          disabled: !_vm.isSubmitEnabled
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.config.signUpButtonLabel
                                            ) +
                                            "\n                            "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.recaptchaSiteKey
                                  ? _c("recaptcha", {
                                      key: "recaptcha",
                                      ref: "recaptcha",
                                      staticClass: "recaptcha",
                                      attrs: {
                                        sitekey: _vm.recaptchaSiteKey,
                                        "load-recaptcha-script": true,
                                        size: "invisible",
                                        badge: "inline"
                                      },
                                      on: {
                                        verify: _vm.onRecaptchaVerify,
                                        expired: _vm.onRecaptchaExpired,
                                        render: _vm.onRecaptchaRender
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("transition", { attrs: { name: "slide" } }, [
                          _vm.message
                            ? _c(
                                "div",
                                {
                                  staticClass: "errorSection",
                                  attrs: { role: "alert" }
                                },
                                [
                                  _c("div", {
                                    staticClass: "errorHeading",
                                    domProps: { innerHTML: _vm._s(_vm.message) }
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    { staticClass: "errorList" },
                                    _vm._l(_vm.errors, function(e) {
                                      return _c(
                                        "li",
                                        { staticClass: "errorListItem" },
                                        [_vm._v(_vm._s(e))]
                                      )
                                    }),
                                    0
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showSocialAccountLink
                  ? _c("link-social-account", {
                      key: "socialLink",
                      attrs: { "account-details": _vm.federatedCredentials },
                      on: { cancel: _vm.socialAccountLinkCancel }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.isRegistrationBlocked
                  ? _c(
                      "div",
                      {
                        key: "registrationBlocked",
                        staticClass: "registrationBlocked"
                      },
                      [
                        _c(
                          "h2",
                          { staticClass: "registrationBlockedHeading" },
                          [
                            _vm._v(
                              _vm._s(_vm.config.errorBirthdayLegalAgeHeading)
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          attrs: { role: "alert" },
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.config.errorBirthdayLegalAgeMessage
                            )
                          }
                        })
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }