import "@Styles/Shared/UserProfile/Inputs/zipCodeInput_sm.less";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import validatedInputBase from './validatedInputBase';
import { ZIP_CODE_REGEX } from '../_constants';
import { testRegex } from '../_util';
export default {
  extends: validatedInputBase,
  props: {
    value: {
      type: String
    },
    config: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      zipCodeErrorMessage: ''
    };
  },
  methods: {
    validateZipCode: function validateZipCode() {
      var regex = ZIP_CODE_REGEX[this.config.zipCodeLocation] || ZIP_CODE_REGEX['United States'];
      var zipCodeRegexCheck = testRegex(regex, this.value);

      if (!zipCodeRegexCheck) {
        this.setIsValid(false);
        this.zipCodeErrorMessage = this.config.errorZipCodeInvalidMessage;
      } else {
        this.zipCodeErrorMessage = '';
        this.setIsValid(true);
      }
    }
  }
};