import "@Styles/Shared/Common/Components/modalBase_sm.less";
import "@Styles/Shared/Common/Components/modalBase_lg.less";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getFocusableElements } from '../Util/_focusHelpers';
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      previousFocusedElement: null,
      ariaLabel: null,
      ariaLabelledBy: null,
      ariaDescribedBy: null
    };
  },
  watch: {
    isVisible: function isVisible(newVal) {
      if (newVal === true) {
        this.$_setFocusOnOpen();
      } else {
        this.$_setFocusOnClose();
      }
    }
  },
  mounted: function mounted() {
    this.$_setFocusOnOpen();
    this.$_setAriaLabels();
  },
  methods: {
    tabEvent: function tabEvent(event) {
      var focusableElements = getFocusableElements(this.$el);
      var first = focusableElements && focusableElements[0];
      var last = focusableElements && focusableElements[focusableElements.length - 1];
      this.$nextTick(function () {
        if (!event.shiftKey && event.target === last) {
          // start back at top
          first && first.focus();
        } else if (event.shiftKey && event.target === first) {
          // loop back to end
          last && last.focus();
        }
      });
    },
    closeModal: function closeModal(event) {
      event.preventDefault(); // parent is in charge of setting isVisible prop to false

      this.$emit('close', event);
    },
    clickOverlay: function clickOverlay(event) {
      this.$refs.closeButton && this.$refs.closeButton.focus();
      event.preventDefault();
      this.$emit('click-overlay');
    },
    $_setFocusOnOpen: function $_setFocusOnOpen() {
      var _this = this;

      this.$nextTick(function () {
        _this.previousFocusedElement = document.activeElement;

        _this.$emit('focus-start');
      });
    },
    $_setFocusOnClose: function $_setFocusOnClose() {
      var _this2 = this;

      this.$nextTick(function () {
        if (_this2.previousFocusedElement) {
          _this2.previousFocusedElement.focus();

          _this2.previousFocusedElement = null;
        }
      });
    },
    $_setAriaLabels: function $_setAriaLabels() {
      // copy labels from element to the role="dialog" container
      this.ariaLabelledBy = this.$el.getAttribute('aria-labelledby');
      this.ariaDescribedBy = this.$el.getAttribute('aria-describedby');
      this.ariaLabel = this.$el.getAttribute('aria-label'); // remove attributes from main container 

      this.$el.removeAttribute('aria-labelledby');
      this.$el.removeAttribute('aria-describedby');
      this.$el.removeAttribute('aria-label');
    }
  }
};