var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "modal",
        class: { visible: _vm.isVisible },
        attrs: {
          "aria-modal": "true",
          role: "dialog",
          "aria-labelledby": _vm.ariaLabelledBy,
          "aria-describedby": _vm.ariaDescribedBy,
          "aria-label": _vm.ariaLabel
        },
        on: {
          keydown: [
            function($event) {
              if (
                !("button" in $event) &&
                _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
              ) {
                return null
              }
              return _vm.closeModal($event)
            },
            function($event) {
              if (
                !("button" in $event) &&
                _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
              ) {
                return null
              }
              return _vm.tabEvent($event)
            }
          ]
        }
      },
      [
        _c("div", { staticClass: "modalHeader" }, [
          _c("div", { staticClass: "modalHeaderText" }, [_vm._t("header")], 2),
          _vm._v(" "),
          _c("div", { staticClass: "modalClose" }, [
            _c(
              "button",
              {
                ref: "closeButton",
                staticClass: "modalCloseButton",
                attrs: { "aria-labelledby": "modalCloseButtonText" },
                on: { click: _vm.closeModal }
              },
              [
                _c("span", { attrs: { id: "modalCloseButtonText" } }, [
                  _vm._v("Close")
                ])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "modalContent" }, [_vm._t("content")], 2),
        _vm._v(" "),
        _c("div", { staticClass: "modalFooter" }, [_vm._t("footer")], 2)
      ]
    ),
    _vm._v(" "),
    _c("div", {
      staticClass: "overlay",
      class: { visible: _vm.isVisible },
      on: { click: _vm.clickOverlay }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }