import "@Styles/Shared/UserProfile/thankYou_sm.less";
import "@Styles/Shared/UserProfile/thankYou_lg.less";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
export default {
  name: 'thankYou',
  data: function data() {
    return {
      config: {}
    };
  },
  methods: {
    init: function init(config) {
      if (config) {
        this.config = _objectSpread({}, config.thankYouSettings);
      }
    }
  }
};