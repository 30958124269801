import "@Styles/Shared/UserProfile/registration_sm.less";
import "@Styles/Shared/UserProfile/registration_md.less";
import "@Styles/Shared/UserProfile/registration_lg.less";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import passwordInput from './Inputs/passwordInput';
import birthdateInput from './Inputs/birthdateInput';
import mcQuestionInput from './Inputs/multiChoiceQuestionInput';
import emailInput from './Inputs/emailInput';
import zipCodeInput from './Inputs/zipCodeInput';
import languagePreferenceInput from './Inputs/languagePreferenceInput';
import socialConnect from './socialConnect';
import recaptcha from 'vue-recaptcha';
/* istanbul ignore next: dynamic import */

var linkSocialAccount = function linkSocialAccount() {
  return import(
  /* webpackChunkName: 'linkSocialAccount' */
  './linkSocialAccount');
};

import { register } from './_api';
import { handleRedirect, newsletterOptInEvent } from './_util';
import { JUST_FOR_YOU_URL, NOT_ELIGIBLE_COOKIE_NAME, IS_LOGGED_IN_COOKIE_NAME, THIRD_PARTY_BLOCKED } from './_constants';
import { cookie } from '@Shared/Common';
export default {
  name: 'registration',
  components: {
    passwordInput: passwordInput,
    birthdateInput: birthdateInput,
    mcQuestionInput: mcQuestionInput,
    emailInput: emailInput,
    zipCodeInput: zipCodeInput,
    languagePreferenceInput: languagePreferenceInput,
    socialConnect: socialConnect,
    linkSocialAccount: linkSocialAccount,
    recaptcha: recaptcha
  },
  data: function data() {
    return {
      config: {},
      // input values
      email: '',
      password: '',
      birthdate: '',
      zipCode: '',
      hhComp: [],
      languagePreference: '',
      newsletterSignUp: false,
      // validation
      isSubmitEnabled: true,
      message: '',
      errors: [],
      isFieldValid: {},
      validatedFields: [],
      isSubscribed: false,
      validateEmailOnLoad: false,
      isRegistrationBlocked: false,
      recaptchaSiteKey: '',
      recaptchaToken: null,
      recaptchaAvailable: false,
      // analytics
      esrcCode: null,
      regAction: '',
      // federated (social) login
      federatedLoginSettings: {},
      isSocialSignUp: false,
      federatedCredentials: null,
      loginProviderName: '',
      loginProviderImage: '',
      socialDetailText: '',
      socialMessage: '',
      showSocialAccountLink: false
    };
  },
  methods: {
    init: function init(config) {
      if (cookie.getCookie(NOT_ELIGIBLE_COOKIE_NAME)) {
        this.isRegistrationBlocked = true;
      } else {
        if (config) {
          this.config = _objectSpread({}, config.registrationSettings);
          this.federatedLoginSettings = config.federatedLoginSettings || {};
          this.validatedFields = config.validatedFields;
          this.esrcCode = config.esrcCode;
          this.regAction = config.regAction;
          this.recaptchaSiteKey = config.registrationSettings && config.registrationSettings.recaptchaPublicKey;

          if (config.email) {
            this.email = config.email;
            this.validateEmailOnLoad = true;
          }
        }
      }
    },
    setSubscriptionStatus: function setSubscriptionStatus(isSubscribed) {
      this.isSubscribed = isSubscribed;
    },
    submit: function submit() {
      var _this = this;

      this.message = '';
      this.errors = [];
      this.isSubmitEnabled = false;
      if (!this.$_validate()) return;
      var newsletterListId = this.newsletterSignUp ? this.config.newsletterListId : null;
      var federatedResponse = null;

      if (this.federatedCredentials) {
        federatedResponse = {
          accessToken: this.federatedCredentials.accessToken,
          idToken: this.federatedCredentials.idToken,
          loginProvider: this.federatedCredentials.loginProvider
        };
      }

      register(this.email, this.password, this.birthdate, this.zipCode, this.hhComp, this.languagePreference, newsletterListId, federatedResponse, this.recaptchaToken).then(function (data) {
        if (data.isValid) {
          cookie.setCookie(IS_LOGGED_IN_COOKIE_NAME, 1, null, true);
          GeneralMills.Analytics.raiseEvent({
            EventType: 'Register',
            PageType: 'Other',
            UserId: data.profileUserId,
            UserStatus: data.accessLevel,
            RegAction: _this.regAction,
            EsrcCode: _this.esrcCode
          });

          if (newsletterListId && data.newsletterSubscription) {
            newsletterOptInEvent(data.newsletterSubscription.listName, data.newsletterSubscription.subscriptionId, 'RegFunnelFullPage');
          }

          var urlParams = new URLSearchParams(window.location.search);

          if (!_this.config.showThankYouPage || data.redirectUrl === JUST_FOR_YOU_URL || urlParams.get('returnUrl') === JUST_FOR_YOU_URL) {
            handleRedirect(data.redirectUrl, _this.config.defaultRedirectUrl);
          } else {
            handleRedirect(_this.config.thankYouUrl, _this.config.defaultRedirectUrl);
          }
        } else {
          if (data.errors && data.errors.find(function (e) {
            return e.ruleId === 'minimum.age.rule';
          })) {
            _this.$_handleLegalAgeError();
          } else {
            var message = data.errors && data.errors[0].message ? _this.config.errorListHeadingText : _this.config.errorGeneralErrorMessage;

            _this.$_setErrorMessages(message || true, data.errors);
          }

          _this.recaptchaToken = null;
        }
      }).catch(function (e) {
        _this.$_setErrorMessages(_this.config.errorGeneralErrorMessage || true);

        _this.recaptchaToken = null;
      });
    },
    setIsFieldValid: function setIsFieldValid(fieldId, status) {
      // check for object status returned from HH Comp
      status !== null && status.isValid !== undefined ? this.$set(this.isFieldValid, fieldId, status.isValid) : this.$set(this.isFieldValid, fieldId, status);
    },
    setLanguagePreference: function setLanguagePreference(value) {
      this.languagePreference = value;
    },
    $_validate: function $_validate() {
      var returnValue = true;
      var errorList = [];
      var requiredFormat = this.config.errorFieldRequiredMessageFormat || '';
      var invalidFormat = this.config.errorFieldInvalidMessageFormat || '';

      for (var i in this.validatedFields) {
        var field = this.validatedFields[i];

        if (!this[field.name] || this[field.name].length === 0) {
          // field is empty
          returnValue = false;
          errorList.push({
            message: requiredFormat.replace('{0}', field.label)
          });
          this.$set(this.isFieldValid, field.id, false);
        } else if (this.isFieldValid[field.id] === false) {
          // validation on the field has failed
          returnValue = false;
          errorList.push({
            message: invalidFormat.replace('{0}', field.label)
          });
        }
      }

      if (!returnValue) {
        this.$_setErrorMessages(this.config.errorListHeadingText, errorList);
      } else {
        if (!this.recaptchaAvailable) {
          var msg = this.config.recaptchaFailureMessage || "We're sorry. Recaptcha validation is down at this time. Please try again later.";
          this.$_setErrorMessages(msg);
          returnValue = false;
        } else if (!this.recaptchaToken) {
          // reset and execute recaptcha - onRecaptchaVerify will be called once complete with new token
          this.$refs.recaptcha.reset();
          this.$refs.recaptcha.execute();
          returnValue = false;
        }
      }

      return returnValue;
    },
    onRecaptchaVerify: function onRecaptchaVerify(token) {
      this.recaptchaToken = token;
      this.submit();
    },
    onRecaptchaExpired: function onRecaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    onRecaptchaRender: function onRecaptchaRender() {
      this.recaptchaAvailable = true;
    },
    socialLogin: function socialLogin() {
      handleRedirect("", this.config.defaultRedirectUrl);
    },
    setFederatedCredentials: function setFederatedCredentials(credentials) {
      var _this2 = this;

      this.federatedCredentials = credentials;

      if (credentials.isExistingUser) {
        this.showSocialAccountLink = true;
      } else {
        this.email = credentials.email;
        this.isSocialSignUp = true; // show details

        this.socialProviderName = credentials.loginProvider;
        this.socialProviderImage = credentials.loginProviderImage;
        this.socialDetailText = this.federatedLoginSettings.continueSignupTextFormat ? this.federatedLoginSettings.continueSignupTextFormat.replace('{0}', this.email) : this.email;
        this.$nextTick(function () {
          _this2.$refs.socialDetailSection && _this2.$refs.socialDetailSection.focus();
        }); // update validated fields

        this.validatedFields = this.validatedFields.filter(function (item) {
          return item.name !== "password" && item.name !== "email";
        });
      }
    },
    socialLoginError: function socialLoginError(showError, reason) {
      if (showError) {
        this.socialMessage = reason === THIRD_PARTY_BLOCKED && this.federatedLoginSettings.thirdPartyBlockedMessage ? this.federatedLoginSettings.thirdPartyBlockedMessage : this.config.errorGeneralErrorMessage;
      } else {
        this.socialMessage = '';
      }
    },
    socialAccountLinkCancel: function socialAccountLinkCancel() {
      var _this3 = this;

      this.showSocialAccountLink = false;
      this.federatedCredentials = {}; // reset focus

      this.$nextTick(function () {
        if (_this3.$refs.registrationForm) {
          var firstInput = _this3.$refs.registrationForm.querySelector('input');

          firstInput && firstInput.focus();
        }
      });
    },
    $_setErrorMessages: function $_setErrorMessages(message, errorList) {
      var _this4 = this;

      this.socialMessage = '';
      this.isSubmitEnabled = !!message || !!errorList;
      this.message = message;
      errorList && errorList.forEach(function (e) {
        e.message && _this4.errors.push(e.message);
        e.fieldId && _this4.$set(_this4.isFieldValid, e.fieldId, false);
      });
    },
    $_handleLegalAgeError: function $_handleLegalAgeError() {
      this.isRegistrationBlocked = true;
      cookie.setCookie(NOT_ELIGIBLE_COOKIE_NAME, NOT_ELIGIBLE_COOKIE_NAME, null, true);
    }
  }
};