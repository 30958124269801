import "@Styles/Shared/UserProfile/Inputs/multiChoiceQuestionInput_sm.less";
import "@Styles/Shared/UserProfile/Inputs/multiChoiceQuestionInput_lg.less";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import validatedInputBase from './validatedInputBase';
export default {
  extends: validatedInputBase,
  props: {
    choices: {
      type: Array
    },
    minimum: {
      type: Number
    },
    maximum: {
      type: Number
    },
    displayValidation: {
      type: Boolean
    },
    requiredMessage: {
      type: String
    },
    value: {
      type: Array
    }
  },
  data: function data() {
    return {
      checkedValues: []
    };
  },
  watch: {
    value: {
      handler: function handler(val) {
        if (JSON.stringify(val) !== JSON.stringify(this.checkedValues)) {
          this.checkedValues = val;
        }
      },
      deep: true
    }
  },
  mounted: function mounted() {
    // If optional (no minimum on load), marked as valid
    if (this.minimum === 0) {
      this.setIsValid({
        isValid: true,
        maxReached: false
      });
    }
  },
  methods: {
    onInput: function onInput() {
      var _this = this;

      this.$nextTick(function () {
        _this.setValidity(); // wait for value to update before emitting


        _this.$emit('input', _this.checkedValues);
      });
    },
    setValidity: function setValidity() {
      // Check for max reached
      var maxReached = false;

      if (this.maximum > 0 && this.checkedValues.length > this.maximum) {
        this.checkedValues.splice(this.maximum, 1);
        maxReached = true;
      } // Determine validity if there is a min


      var isValid = !(this.minimum > 0) || this.checkedValues && this.checkedValues.length >= this.minimum;
      this.setIsValid({
        isValid: isValid,
        maxReached: maxReached
      });
    }
  }
};