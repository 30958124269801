
import validatedInputBase from './validatedInputBase';
export default {
  extends: validatedInputBase,
  props: {
    value: {
      type: String
    },
    config: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    hideButtonLabel: {
      type: String,
      default: 'Hide password'
    },
    showButtonLabel: {
      type: String,
      default: 'Show password'
    }
  },
  data: function data() {
    return {
      isPasswordShown: false
    };
  },
  computed: {
    fieldType: function fieldType() {
      return this.isPasswordShown ? 'text' : 'password';
    },
    passwordButtonText: function passwordButtonText() {
      return this.isPasswordShown ? this.hideButtonLabel : this.showButtonLabel;
    }
  },
  methods: {
    toggleShowPassword: function toggleShowPassword() {
      this.isPasswordShown = !this.isPasswordShown;
    },
    onEnter: function onEnter() {
      this.$emit('enter');
    }
  }
};