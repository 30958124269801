var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "languagePreferenceInput" }, [
    _c(
      "div",
      { staticClass: "languagePreferenceField" },
      [
        _c(
          "label",
          { staticClass: "languagePreferenceLabel" },
          [
            _vm._t("default"),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "fieldInputParent", class: _vm.validationClass },
              [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedValue,
                        expression: "selectedValue"
                      }
                    ],
                    staticClass: "smallInput languagePreferenceFieldInput",
                    attrs: { "aria-invalid": _vm.isValid === false },
                    on: {
                      blur: _vm.validateLanguagePreference,
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedValue = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c(
                      "option",
                      { attrs: { value: "", disabled: "", selected: "" } },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.config.languagePreferenceDefaultOptionLabel
                          )
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.config.languagePreferenceOptions, function(
                      language
                    ) {
                      return _c(
                        "option",
                        { domProps: { value: language.value } },
                        [
                          _vm._v(
                            "\n                        " +
                              _vm._s(language.label) +
                              "\n                    "
                          )
                        ]
                      )
                    })
                  ],
                  2
                )
              ]
            )
          ],
          2
        ),
        _vm._v(" "),
        _c("transition", { attrs: { name: "slide" } }, [
          _vm.isValid === false
            ? _c("div", { attrs: { role: "alert" } }, [
                _c("div", { staticClass: "inputErrors" }, [
                  _vm._v(_vm._s(_vm.requiredMessage))
                ])
              ])
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }