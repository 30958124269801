import "@Styles/Shared/UserProfile/socialConnect_sm.less";
import "@Styles/Shared/UserProfile/socialConnect_lg.less";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
//
//
//
//
//
//
//
//
//
//
import { getProviderSdk } from './FederatedLogin/_sdk';
import { federatedAuthorize } from './_api';
import { SOCIAL_PROVIDER_TYPE, IS_LOGGED_IN_COOKIE_NAME } from './_constants';
import { cookie } from '@Shared/Common';
export default {
  props: {
    providerName: {
      type: String
    },
    providerImage: {
      type: String
    },
    socialClientId: {
      type: String
    }
  },
  data: function data() {
    return {
      sdk: null
    };
  },
  computed: {
    providerType: function providerType() {
      return SOCIAL_PROVIDER_TYPE[this.providerName];
    }
  },
  mounted: function mounted() {
    this.sdk = getProviderSdk(this.providerType);
  },
  methods: {
    connect: function connect() {
      var _this = this;

      this.$emit('social-login-error', false);
      this.sdk.signIn(this.socialClientId).then(function (socialResponse) {
        if (socialResponse) {
          federatedAuthorize(_this.providerName, socialResponse.accessToken, socialResponse.idToken, socialResponse.email).then(function (serverResponse) {
            var result = serverResponse.result;

            if (result === 'Success') {
              cookie.setCookie(IS_LOGGED_IN_COOKIE_NAME, 1, null, true);
              GeneralMills.Analytics.raiseEvent({
                'EventType': 'Login',
                UserId: serverResponse.profileUserId,
                UserStatus: serverResponse.accessLevel
              });

              _this.$emit('social-login');
            } else if (result === 'NotFound') {
              var federatedCredentials = _objectSpread({}, socialResponse, {
                loginProvider: _this.providerName,
                loginProviderImage: _this.providerImage,
                isExistingUser: serverResponse.isExistingUser
              });

              _this.$emit('social-sign-up-credentials', federatedCredentials);
            } else {
              _this.$emit('social-login-error', true);
            }
          }).catch(function (e) {
            _this.$emit('social-login-error', true, e.gmiReason);
          });
        } // don't show an error if the response is empty - this means the user ignored the request to sign in

      }).catch(function (e) {
        _this.$emit('social-login-error', true, e.gmiReason);
      });
    }
  }
};