import "@Styles/Shared/UserProfile/requestPasswordReset_sm.less";
import "@Styles/Shared/UserProfile/requestPasswordReset_lg.less";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import { requestPasswordResetCall } from './_api';
export default {
  name: 'requestPasswordReset',
  data: function data() {
    return {
      config: {},
      email: '',
      isSubmitEnabled: true,
      success: false,
      error: false,
      errorMessage: ''
    };
  },
  computed: {
    emailInputClass: function emailInputClass() {
      return this.error ? 'inputError' : '';
    }
  },
  methods: {
    init: function init(config) {
      if (config) {
        this.config = _objectSpread({}, config.requestPasswordResetSettings, {
          // one level for all the labels
          federatedLoginSettings: config.federatedLoginSettings
        });
      }
    },
    onEmailChange: function onEmailChange() {
      this.$_clearErrorMessage();
    },
    submit: function submit() {
      var _this = this;

      this.isSubmitEnabled = false;
      this.$_clearErrorMessage();

      if (!this.$_validate()) {
        this.isSubmitEnabled = true;
        return;
      }

      requestPasswordResetCall(this.email).then(function (data) {
        if (data.isValid) {
          _this.success = true;

          _this.$nextTick(function () {
            _this.$refs.successMessage && _this.$refs.successMessage.focus();
          });
        } else {
          _this.$_setErrorMessage(_this.config.emailNotFoundMessage);
        }

        _this.isSubmitEnabled = true;
      });
    },
    $_validate: function $_validate() {
      if (this.email && this.email !== '') {
        return true;
      } else {
        this.$_setErrorMessage(this.config.emailRequiredMessage);
        return false;
      }
    },
    $_setErrorMessage: function $_setErrorMessage(message) {
      this.error = true;
      this.errorMessage = message;
    },
    $_clearErrorMessage: function $_clearErrorMessage() {
      this.error = false;
      this.errorMessage = '';
    }
  }
};