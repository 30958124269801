var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.config
    ? _c("div", { staticClass: "surveyNavigation" }, [
        _vm.showErrorMessage
          ? _c(
              "section",
              { staticClass: "errorMessage", attrs: { role: "alert" } },
              [_c("p", [_vm._v(_vm._s(_vm.errorMessage))])]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("section", { staticClass: "navigationButtons" }, [
          _vm.showPreviousButton
            ? _c(
                "button",
                {
                  staticClass: "previousButton",
                  attrs: { type: "button" },
                  on: { click: _vm.previous }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.config.previousButtonText) +
                      "\n        "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showNextButton
            ? _c(
                "button",
                {
                  staticClass: "nextButton",
                  attrs: { type: "button" },
                  on: { click: _vm.next }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.config.nextButtonText) +
                      "\n        "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showCompleteButton
            ? _c(
                "button",
                {
                  staticClass: "completeButton",
                  attrs: { type: "button" },
                  on: { click: _vm.complete }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.config.completeButtonText) +
                      "\n        "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isRegistrationStep
            ? _c(
                "a",
                {
                  staticClass: "registrationLink",
                  attrs: { href: _vm.config.registrationLinkUrl }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.config.registrationLinkText) +
                      "\n        "
                  )
                ]
              )
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }