var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "passwordInput" }, [
    _c("div", { staticClass: "passwordField" }, [
      _c(
        "label",
        { staticClass: "passwordLabel" },
        [
          _vm._t("default"),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "fieldInputParent", class: _vm.validationClass },
            [
              _c("input", {
                ref: "passwordFieldInput",
                staticClass: "passwordFieldInput",
                attrs: {
                  type: _vm.fieldType,
                  "aria-describedby": _vm.showCriteria
                    ? "passwordCriteriaBox"
                    : null,
                  "aria-invalid": _vm.showCriteria && _vm.isValid === false,
                  autocomplete: _vm.autocompleteValue
                },
                domProps: { value: _vm.value },
                on: {
                  input: _vm.onInput,
                  blur: _vm.onBlur,
                  keydown: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.onEnter($event)
                  }
                }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "validationSymbol" })
            ]
          )
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "passwordButtonContainer" }, [
        _c(
          "button",
          {
            staticClass: "showPasswordButton",
            attrs: { type: "button" },
            on: { click: _vm.toggleShowPassword }
          },
          [_vm._v(_vm._s(_vm.passwordButtonText))]
        )
      ])
    ]),
    _vm._v(" "),
    _vm.showCriteria
      ? _c("div", { attrs: { id: "passwordCriteriaBox" } }, [
          _c("div", { staticClass: "criteriaHeading" }, [
            _vm._v(_vm._s(_vm.config.passwordCriteriaHeading))
          ]),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "criteriaItems" },
            _vm._l(_vm.passwordCriteria, function(criteria) {
              return _c(
                "li",
                {
                  key: criteria.ref,
                  ref: criteria.ref,
                  refInFor: true,
                  staticClass: "criteriaItem",
                  class: _vm.$_criteriaClass(criteria),
                  attrs: { role: criteria.valid === false ? "alert" : null }
                },
                [
                  _c("span", { staticClass: "criteriaIcon" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "criteriaDetail" }, [
                    _vm._v(_vm._s(criteria.text))
                  ])
                ]
              )
            }),
            0
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }