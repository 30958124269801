import "@Styles/Shared/UserProfile/Inputs/languagePreferenceInput_sm.less";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import validatedInputBase from './validatedInputBase';
export default {
  extends: validatedInputBase,
  props: {
    config: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      selectedValue: ''
    };
  },
  methods: {
    validateLanguagePreference: function validateLanguagePreference() {
      this.selectedValue && this.selectedValue.length > 0 ? this.setIsValid(true) : this.setIsValid(false);
      this.$emit('set-language-preference', this.selectedValue);
    }
  }
};