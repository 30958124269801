var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "confirmPasswordInput" }, [
    _c(
      "div",
      { staticClass: "confirmPasswordField" },
      [
        _c(
          "label",
          { staticClass: "confirmPasswordLabel" },
          [
            _vm._t("default"),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "fieldInputParent", class: _vm.validationClass },
              [
                _c("input", {
                  ref: "confirmPasswordFieldInput",
                  staticClass: "confirmPasswordFieldInput",
                  attrs: {
                    type: _vm.fieldType,
                    "aria-invalid": _vm.isValid === false,
                    autocomplete: "new-password"
                  },
                  domProps: { value: _vm.value },
                  on: {
                    input: _vm.onInput,
                    blur: _vm.onBlur,
                    keydown: function($event) {
                      if (
                        !("button" in $event) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.onEnter($event)
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "validationSymbol" })
              ]
            )
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "passwordButtonContainer" }, [
          _c(
            "button",
            {
              staticClass: "showPasswordButton",
              attrs: { type: "button" },
              on: { click: _vm.toggleShowPassword }
            },
            [_vm._v(_vm._s(_vm.passwordButtonText))]
          )
        ]),
        _vm._v(" "),
        _c("transition", { attrs: { name: "slide" } }, [
          _vm.passwordMatches === false
            ? _c(
                "div",
                { staticClass: "alert-error", attrs: { role: "alert" } },
                [_c("span", [_vm._v(_vm._s(_vm.mismatchMessage))])]
              )
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }