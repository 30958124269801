import "@Styles/Pages/Profile/profile_sm.less";
import "@Styles/Pages/Profile/profile_md.less";
import "@Styles/Pages/Profile/profile_lg.less";
import registration from '@Shared/UserProfile/Scripts/registration';
import login from '@Shared/UserProfile/Scripts/login';
import requestPasswordReset from '@Shared/UserProfile/Scripts/requestPasswordReset';
import resetPassword from '@Shared/UserProfile/Scripts/resetPassword';
import survey from '@Shared/UserProfile/Scripts/Survey/survey';
import thankYou from '@Shared/UserProfile/Scripts/thankYou';

(function () {
  GeneralMills.PandoSites.RegisterModules({
    registration: registration,
    login: login,
    requestPasswordReset: requestPasswordReset,
    resetPassword: resetPassword,
    survey: survey,
    thankYou: thankYou
  });
})();