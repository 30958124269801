import "@Styles/Shared/UserProfile/login_sm.less";
import "@Styles/Shared/UserProfile/login_lg.less";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import passwordInput from './Inputs/passwordInput';
import socialConnect from './socialConnect';
import recaptcha from 'vue-recaptcha';
/* istanbul ignore next: dynamic import */

var linkSocialAccount = function linkSocialAccount() {
  return import(
  /* webpackChunkName: 'linkSocialAccount' */
  './linkSocialAccount');
};

import { authenticate as _authenticate } from './_api';
import { handleRedirect } from './_util';
import { IS_LOGGED_IN_COOKIE_NAME, THIRD_PARTY_BLOCKED } from './_constants';
import { cookie } from '@Shared/Common';
export default {
  name: 'login',
  components: {
    passwordInput: passwordInput,
    socialConnect: socialConnect,
    linkSocialAccount: linkSocialAccount,
    recaptcha: recaptcha
  },
  data: function data() {
    return {
      config: {},
      // input values
      email: '',
      password: '',
      rememberMe: false,
      // validation
      message: '',
      recaptchaSiteKey: '',
      recaptchaToken: null,
      recaptchaAvailable: false,
      isSubmitEnabled: true,
      // federated (social) login
      federatedLoginSettings: {},
      federatedCredentials: {},
      showSocialAccountLink: false
    };
  },
  methods: {
    init: function init(config) {
      if (config) {
        this.config = _objectSpread({}, config.loginSettings);
        this.federatedLoginSettings = config.federatedLoginSettings || {};
        this.email = config.email;
        this.recaptchaSiteKey = config.loginSettings && config.loginSettings.recaptchaPublicKey;
      }
    },
    authenticate: function authenticate() {
      var _this = this;

      if (!this.recaptchaAvailable) {
        var msg = this.config.recaptchaFailureMessage || "We're sorry. Recaptcha validation is down at this time. Please try again later.";
        this.$_setErrorMessage(msg);
      } else if (!this.recaptchaToken) {
        // reset and execute recaptcha - onRecaptchaVerify will be called once complete with new token
        this.$refs.recaptcha.reset();
        this.$refs.recaptcha.execute();
      } else {
        this.$_setErrorMessage('');

        _authenticate(this.email, this.password, this.rememberMe, this.recaptchaToken).then(function (data) {
          if (data.isValid) {
            cookie.setCookie(IS_LOGGED_IN_COOKIE_NAME, 1, null, true);
            GeneralMills.Analytics.raiseEvent({
              'EventType': 'Login',
              UserId: data.profileUserId,
              UserStatus: data.accessLevel
            });
            handleRedirect(data.redirectUrl, _this.config.defaultRedirectUrl);
          } else {
            var _msg = data.errors && data.errors[0].message ? data.errors[0].message : _this.config.invalidLoginMessage;

            _this.$_setErrorMessage(_msg || true);

            _this.recaptchaToken = null;
          }
        }).catch(function (e) {
          _this.$_setErrorMessage(_this.config.generalErrorMessage || true);

          _this.recaptchaToken = null;
        });
      }
    },
    socialLogin: function socialLogin() {
      handleRedirect("", this.config.defaultRedirectUrl);
    },
    socialLoginError: function socialLoginError(showError, reason) {
      var msg = '';

      if (showError) {
        msg = reason === THIRD_PARTY_BLOCKED && this.federatedLoginSettings.thirdPartyBlockedMessage ? this.federatedLoginSettings.thirdPartyBlockedMessage : this.config.generalErrorMessage || true;
      }

      this.$_setErrorMessage(msg);
    },
    setFederatedCredentials: function setFederatedCredentials(credentials) {
      this.federatedCredentials = credentials;

      if (credentials.isExistingUser) {
        this.showSocialAccountLink = true;
      } else {
        // they don't currently have an account
        this.$_setErrorMessage(this.config.invalidLoginMessage || true);
      }
    },
    socialAccountLinkCancel: function socialAccountLinkCancel() {
      var _this2 = this;

      this.showSocialAccountLink = false;
      this.federatedCredentials = {}; // reset focus

      this.$nextTick(function () {
        if (_this2.$refs.loginForm) {
          var firstInput = _this2.$refs.loginForm.querySelector('input');

          firstInput && firstInput.focus();
        }
      });
    },
    onRecaptchaVerify: function onRecaptchaVerify(token) {
      this.recaptchaToken = token;
      this.authenticate();
    },
    onRecaptchaExpired: function onRecaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    onRecaptchaRender: function onRecaptchaRender() {
      this.recaptchaAvailable = true;
    },
    $_setErrorMessage: function $_setErrorMessage(message) {
      this.message = message;
      this.isSubmitEnabled = !!message;
    }
  }
};