import "@Styles/Shared/UserProfile/Inputs/confirmPasswordInput_sm.less";
import "@Styles/Shared/UserProfile/Inputs/confirmPasswordInput_lg.less";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import passwordInputBase from './passwordInputBase';
import { PASSWORD_FULL_REGEX } from "../_constants";
import { testRegex } from '../_util';
export default {
  extends: passwordInputBase,
  props: {
    valueToMatch: {
      type: String
    },
    mismatchMessage: {
      type: String
    },
    updatePasswordMatch: {
      type: Boolean
    }
  },
  data: function data() {
    return {
      passwordMatches: null
    };
  },
  watch: {
    updatePasswordMatch: function updatePasswordMatch(val) {
      this.passwordMatches = val;
    }
  },
  methods: {
    onInput: function onInput(event) {
      var _this = this;

      this.$emit('input', event.target.value);
      this.$nextTick(function () {
        if (_this.value === _this.valueToMatch) {
          _this.passwordMatches = true;
          testRegex(PASSWORD_FULL_REGEX, _this.value) ? _this.setIsValid(true) : _this.setIsValid(null);
        } else {
          _this.passwordMatches = null;

          _this.setIsValid(null);
        }
      });
    },
    onBlur: function onBlur() {
      var _this2 = this;

      this.$nextTick(function () {
        if (_this2.value === _this2.valueToMatch) {
          _this2.passwordMatches = true;
          testRegex(PASSWORD_FULL_REGEX, _this2.value) ? _this2.setIsValid(true) : _this2.setIsValid(false);
        } else {
          _this2.passwordMatches = false;

          _this2.setIsValid(false);
        }
      });
    }
  }
};