var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "zipCodeInput" }, [
    _c(
      "div",
      { staticClass: "zipCodeField" },
      [
        _c(
          "label",
          { staticClass: "zipCodeLabel" },
          [
            _vm._t("default"),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "fieldInputParent", class: _vm.validationClass },
              [
                _c("input", {
                  staticClass: "smallInput zipCodeFieldInput",
                  attrs: {
                    type: "text",
                    autocomplete: "postal-code",
                    "aria-invalid": _vm.isValid === false
                  },
                  domProps: { value: _vm.value },
                  on: {
                    input: function($event) {
                      _vm.$emit("input", $event.target.value)
                    },
                    blur: _vm.validateZipCode
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "validationSymbol" })
              ]
            )
          ],
          2
        ),
        _vm._v(" "),
        _c("transition", { attrs: { name: "slide" } }, [
          _vm.isValid === false
            ? _c("div", { attrs: { role: "alert" } }, [
                _vm.value
                  ? _c("div", { staticClass: "inputErrors" }, [
                      _vm._v(" " + _vm._s(_vm.zipCodeErrorMessage) + " ")
                    ])
                  : _c("div", { staticClass: "inputErrors" }, [
                      _vm._v(" " + _vm._s(_vm.requiredMessage) + " ")
                    ])
              ])
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }