var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "emailInput" }, [
    _c(
      "div",
      { staticClass: "emailField" },
      [
        _c(
          "label",
          { staticClass: "emailLabel" },
          [
            _vm._t("default"),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "fieldInputParent", class: _vm.validationClass },
              [
                _c("input", {
                  staticClass: "emailFieldInput",
                  attrs: {
                    type: "email",
                    autocomplete: "username",
                    "aria-invalid": _vm.isValid === false
                  },
                  domProps: { value: _vm.value },
                  on: {
                    input: function($event) {
                      _vm.$emit("input", $event.target.value)
                    },
                    blur: _vm.validateEmail,
                    "set-subscription-status": function($event) {
                      _vm.$emit(
                        "set-subscription-status",
                        $event.target.isSubscribed
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "validationSymbol" })
              ]
            )
          ],
          2
        ),
        _vm._v(" "),
        _c("transition", { attrs: { name: "slide" } }, [
          _vm.isValid === false
            ? _c("div", { attrs: { role: "alert" } }, [
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.value,
                      expression: "value"
                    }
                  ],
                  staticClass: "inputEmailErrorMessage",
                  domProps: { innerHTML: _vm._s(_vm.emailErrorMessage) }
                }),
                _vm._v(" "),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.emailErrorSubMessage && _vm.value,
                      expression: "emailErrorSubMessage && value"
                    }
                  ],
                  staticClass: "emailSubError",
                  domProps: { innerHTML: _vm._s(_vm.emailErrorSubMessage) }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.value,
                        expression: "!value"
                      }
                    ],
                    staticClass: "inputErrors"
                  },
                  [_vm._v(" " + _vm._s(_vm.requiredMessage) + " ")]
                )
              ])
            : _vm._e()
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }