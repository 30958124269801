import "@Styles/Shared/UserProfile/Survey/surveyNavigation_sm.less";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapActions, mapGetters } from 'vuex';
export default {
  computed: _objectSpread({}, mapGetters('_surveyStore', ['config', 'currentStepNumber', 'stepCount', 'errorMessage', 'isRegistrationStep', 'isAuthenticatedUser']), {
    showErrorMessage: function showErrorMessage() {
      return !!this.errorMessage;
    },
    showPreviousButton: function showPreviousButton() {
      return !this.isRegistrationStep && this.currentStepNumber > 0;
    },
    showNextButton: function showNextButton() {
      return this.currentStepNumber > 0 && this.currentStepNumber < this.stepCount - 1 && this.isAuthenticatedUser || this.currentStepNumber > 0 && this.currentStepNumber < this.stepCount - 2 && !this.isAuthenticatedUser;
    },
    showCompleteButton: function showCompleteButton() {
      return this.currentStepNumber === this.stepCount - 1 && this.isAuthenticatedUser || this.currentStepNumber === this.stepCount - 2 && !this.isAuthenticatedUser;
    }
  }),
  methods: _objectSpread({}, mapActions('_surveyStore', ['setCurrentStepNumber']), {
    previous: function previous() {
      this.setCurrentStepNumber(this.currentStepNumber - 1);
    },
    next: function next() {
      this.setCurrentStepNumber(this.currentStepNumber + 1);
    },
    complete: function complete() {
      this.isAuthenticatedUser ? this.$emit('complete') : this.next();
    }
  })
};