var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "birthdateInput" },
    [
      _c("fieldset", [
        _c("legend", [_vm._t("default")], 2),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "fieldInputParent", class: _vm.validationClass },
          [
            _c("div", { staticClass: "birthdateInputContainer" }, [
              _c(
                "div",
                {
                  ref: "dateInputBox",
                  staticClass: "dateInputBox",
                  class: { focused: _vm.isFocused }
                },
                [
                  _vm._l(_vm.dateFormat, function(item, index) {
                    return [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.dateItems[item].value,
                            expression: "dateItems[item].value"
                          }
                        ],
                        key: index,
                        ref: "input" + index,
                        refInFor: true,
                        class: [
                          "dateInputField",
                          _vm.dateItems[item].itemClass
                        ],
                        attrs: {
                          type: "number",
                          "aria-label": _vm.config[_vm.dateItems[item].label],
                          placeholder: item,
                          autocomplete: _vm.dateItems[item].autocomplete,
                          "aria-invalid": _vm.isValid === false
                        },
                        domProps: { value: _vm.dateItems[item].value },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.dateItems[item],
                                "value",
                                $event.target.value
                              )
                            },
                            function($event) {
                              _vm.handleInput(
                                $event,
                                _vm.dateItems[item],
                                index
                              )
                            }
                          ],
                          focus: _vm.onDateFocus,
                          blur: _vm.onDateBlur
                        }
                      }),
                      _vm._v(" "),
                      index < 1
                        ? _c(
                            "span",
                            {
                              key: "separator" + index,
                              staticClass: "dateSeparator"
                            },
                            [_vm._v("/")]
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "validationSymbol" })
          ]
        )
      ]),
      _vm._v(" "),
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.isValid === false
          ? _c(
              "div",
              { staticClass: "birthdateInputErrors", attrs: { role: "alert" } },
              [
                _vm.value
                  ? _c("div", { staticClass: "inputErrors" }, [
                      _vm._v(_vm._s(_vm.config.errorBirthdayInvalidMessage))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.value
                  ? _c("div", { staticClass: "inputErrors" }, [
                      _vm._v(_vm._s(_vm.requiredMessage))
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }