import "@Styles/Shared/UserProfile/Inputs/emailInput_sm.less";
import "@Styles/Shared/UserProfile/Inputs/emailInput_lg.less";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import validatedInputBase from './validatedInputBase';
import { validateEmail as _validateEmail } from '../_api';
import { EMAIL_REGEX } from '../_constants';
import { testRegex } from '../_util';
export default {
  extends: validatedInputBase,
  props: {
    value: {
      type: String
    },
    config: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    validateOnLoad: false
  },
  data: function data() {
    return {
      emailErrorMessage: '',
      emailErrorSubMessage: '',
      isSubscribed: false
    };
  },
  watch: {
    validateOnLoad: function validateOnLoad(newVal) {
      if (newVal) {
        this.validateEmail();
      }
    }
  },
  methods: {
    validateEmail: function validateEmail() {
      var _this = this;

      var emailRegexCheck = testRegex(EMAIL_REGEX, this.value);

      if (!emailRegexCheck) {
        this.setIsValid(false);
        this.$_setIsSubscribed(false);
        this.$_setErrorMessages(this.config.errorEmailInvalidMessage, '');
      } else {
        _validateEmail(this.value).then(function (response) {
          return _this.$_handleEmailValidationSuccess(response);
        }).catch(function () {
          return _this.$_handleEmailValidationError();
        });
      }
    },
    $_handleEmailValidationSuccess: function $_handleEmailValidationSuccess(response) {
      var isRegisteredUser = response && response.isRegisteredUser;
      this.setIsValid(response != null ? !isRegisteredUser : false);

      if (isRegisteredUser) {
        this.$_setErrorMessages(this.config.errorEmailExistsMessage, this.config.errorEmailExistsLogInCopy);
      } else {
        this.$_setErrorMessages('', '');
      } // isSubscribed is used to show/hide the newsletter section of the registration form. We only want to hide it when the user is subscribed to
      // newsletter but not registered. Therefore, we need to check if the response is true because it would hide from a new user if we only check isRegisteredUser


      this.$_setIsSubscribed(response && !isRegisteredUser ? response.newsletterSubscriptions.length > 0 : false);
    },
    $_handleEmailValidationError: function $_handleEmailValidationError() {
      // think about how we handle API errors - should it be "successful" or show no indicator?
      this.setIsValid(true);
      this.$_setIsSubscribed(false);
      this.$_setErrorMessages('', '');
    },
    $_setIsSubscribed: function $_setIsSubscribed(val) {
      var _this2 = this;

      this.isSubscribed = val;
      this.$nextTick(function () {
        _this2.$emit('set-subscription-status', val);
      });
    },
    $_setErrorMessages: function $_setErrorMessages(message, subMessage) {
      this.emailErrorMessage = message;
      this.emailErrorSubMessage = subMessage;
    }
  }
};