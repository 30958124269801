var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.config
    ? _c("div", { staticClass: "surveyIntro" }, [
        _c("div", { staticClass: "surveyIntroImageContainer" }, [
          _c("img", {
            staticClass: "brandLogo",
            attrs: {
              src: _vm.config.brandLogoImageUrl,
              alt: "",
              "aria-hidden": "true"
            }
          }),
          _vm._v(" "),
          _c(
            "section",
            {
              staticClass: "imageCarousel",
              attrs: { "aria-labelledby": "imageCarouselHeader" }
            },
            [
              _c("p", { attrs: { id: "imageCarouselHeader" } }, [
                _vm._v(_vm._s(_vm.config.imageCarouselHeaderText))
              ]),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(_vm.carouselImages, function(carouselImage, index) {
                  return _c(
                    "li",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: carouselImage.visible,
                          expression: "carouselImage.visible"
                        }
                      ],
                      staticClass: "slide",
                      class: _vm.currentIndex === index ? "active" : ""
                    },
                    [
                      _c("img", {
                        staticClass: "carouselImage",
                        attrs: { src: carouselImage.imageUrl, alt: "" }
                      })
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "animationButton",
                  class: _vm.animationActive ? "iconPause" : "iconPlay",
                  attrs: { "aria-labelledby": "animationButtonText" },
                  on: { click: _vm.animateCarousel }
                },
                [
                  _c("span", { attrs: { id: "animationButtonText" } }, [
                    _vm._v(_vm._s(_vm.carouselButtonText))
                  ])
                ]
              )
            ]
          ),
          _vm._v(" "),
          _vm.config.accentImageUrl
            ? _c("img", {
                staticClass: "accentImageMobile",
                attrs: {
                  src: _vm.config.accentImageUrl,
                  alt: "",
                  "aria-hidden": "true"
                }
              })
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("section", { staticClass: "surveyIntroContent" }, [
          _c("h1", { staticClass: "surveyIntroHeader" }, [
            _vm.config.introductionHeaderImageUrl
              ? _c("img", {
                  staticClass: "surveyIntroHeaderImage",
                  attrs: {
                    src: _vm.config.introductionHeaderImageUrl,
                    alt: _vm.config.introductionHeaderText
                  }
                })
              : _c("span", [_vm._v(_vm._s(_vm.config.introductionHeaderText))])
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "surveyIntroDescription" }, [
            _vm._v(_vm._s(_vm.config.introductionDescriptionText))
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "surveyGetStarted nextButton",
              attrs: { type: "button" },
              on: { click: _vm.start }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.config.getStartedButtonText) +
                  "\n        "
              )
            ]
          ),
          _vm._v(" "),
          _vm.showLoginContainer
            ? _c("section", { staticClass: "logInContainer" }, [
                _c("span", [_vm._v(_vm._s(_vm.config.alreadyAMemberText))]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "logInLink",
                    attrs: { href: _vm.config.logInLinkUrl },
                    on: { click: _vm.logInLinkEvent }
                  },
                  [_vm._v(_vm._s(_vm.config.logInLinkText))]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.config.accentImageUrl
            ? _c("img", {
                staticClass: "accentImageDesktop",
                attrs: {
                  src: _vm.config.accentImageUrl,
                  alt: "",
                  "aria-hidden": "true"
                }
              })
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }